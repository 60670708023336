<template>
  <transition name="modal">
    <div class="modal-mask" style="overflow-y: scroll;" @click="hide()">
      <div v-if="getModalInfo" class="modal-container" @click.stop>

        <div class="send-custom-offer-main">
          <div class="close-div">
            <img  @click="hide()" style="opacity: 1; cursor:pointer;width: 16px; height: 16px;"
                  src="https://gcdn.bionluk.com/site/icon/tansel_modal_close_ince.svg">
          </div>
          <div v-if="!submitted" style="width: 100%;">
            <p class="custom-offer-header" style="font-size: 24px;">Neden bu gönderiyi raporluyorsun?</p>
            <div class="report-actions-div" @click="submitPage(4, 'Bu gönderi Bionluk\'a uygun değil')" style="margin-top: 50px;">
              <p>Bu gönderi Bionluk'a uygun değil.</p>
              <img src="https://gcdn.bionluk.com/site/icon/tansel_mobil_ws_ok.svg"/>
            </div>
            <div class="seperator"></div>
            <div class="report-actions-div" @click="submitPage(1, 'Bu gönderi sahte veya aldatmaca')">
              <p>Bu gönderi sahte veya aldatmaca..</p>
              <img src="https://gcdn.bionluk.com/site/icon/tansel_mobil_ws_ok.svg"/>
            </div>
          </div>
          <div v-else style="width: 100%;">
            <p class="custom-offer-header" style="font-size: 24px;">Onaylayın</p>
            <div class="report-actions-div" style="margin-top: 50px; cursor: auto; color: #8b95a1;">
              <p>Bu gönderiyi raporlamak üzeresin. Raporlamak istediğin neden: <span style="color: #2d3640; font-size: 18px; font-weight: 500; font-style: italic;">"{{text}}."</span></p>
            </div>
            <div class="report-buttons-div">
              <button @click="backButton()" class="editButton" style="">Geri</button>
              <button @click="sendReport()" class="saveButton" style="margin-left: 10px;">Gönder</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>


</template>

<script>

  export default {
    name: "src-pages-modals-report-v1_mobile",
    data () {
      return {
        post: null,
        submitted: false,
        text: "",
        reportTypeId: 0
      }
    },
    methods: {
      hide() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },

      sendReport() {
        this.api.general.report("post", this.post.post_id, this.reportTypeId)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.$toasted.global.infoToast({description: result.message});
              this.hide();
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      backButton() {
        this.submitted = false;
        this.reportTypeId = 0;
        this.text = "";
      },

      submitPage(reportTypeId, text) {
        this.reportTypeId = reportTypeId;
        this.text = text;
        this.submitted = true;
      }
    },

    computed: {
      getModalInfo() {
        let info = this.$store.state.activeModal.info;
        if (info) {
          this.post = info.post;
        }
        return true;
      }
    }



  }

</script>

<style scoped lang="scss">
  .modal-container {
    margin-top: 60px;
    padding: 0;
    font-family: 'sofia-pro';
    overflow-y: scroll;
    width: calc(100vw - 30px) !important;
    max-width: 450px !important;
    height: calc(100vh - 50px) !important;
    max-height: 415px;
    background-color: #ffffff;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #ffffff;
    position: relative;
  }

  .send-custom-offer-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
    padding: 50px 15px 20px 15px;
  }

  .custom-offer-header {
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    color: #2d3640;
  }

  .custom-offer-label {
    font-size: 14px;
    text-align: left;
    color: #2d3640;
    margin-top: 20px;
    margin-bottom: 10px;
  }


  .report-actions-div {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
    p {

    }
  }

  .seperator {
    width: 100%;
    height: 2px;
    border-bottom: solid 1px #dfe4ec;
  }

  .super-button {
    min-width: 130px;
    height: 50px;
    border-radius: 2px;
    background-color: #FD4056;
    &:hover {
      background-color: #e94258;
    }


  }



  .close-div {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .report-buttons-div {
    width: 280px;

    margin-right: 20px;
    padding-right: 20px;
    margin-top: 30px;
    text-align: right;
  }
</style>
